/* VARIABLES */

/* Default values */
:root {

    /* COLORS */
  
    /* Primary */
    --efxPrimary: #0076A3;
  
    /* Status */
    --efxStatus: #3FAE29;
  
    /* Text */
    --efxTextDark: #333D47;
    --efxTextLight: #5F6A72;
    --efxTextError: #80001C;
  
    /* Interactive */
    --efxInteractiveLight: #0093C9;
    --efxInteractiveDark: #005670;
    --efxInteractiveFocus: #0F62FE;
    --efxInteractiveDisabled: #E5EBED;
  
    /* UI */
    --efxBackgroundError: #FFEBEF;
    --efxBackgroundDark: #CED4DA;
    --efxBackgroundLight: #FFFFFF;
  
    /* SIZES */
    --efxFontSize: 16px;
    --efxLineHeight: 24px;
    --efxInputRadius: 4px;
    --efxContainerWidth: auto;
    --efxContainerMargin: 0 auto;
    --efxContainerPadding: 30px 16px;
  }
  
  @media (min-width: 359px) {
    :root {
      --efxContainerWidth: auto;
    }
  }
  
  @media (min-width: 599px) {
    :root {
      --efxContainerWidth: 520px;
    }
  }
  
  @media (min-width: 767px) {
    :root {
      --efxContainerWidth: 700px;
      --efxContainerPadding: 30px 0;
    }
  }
  
  @media (min-width: 1439px) {
    :root {
      --efxContainerWidth: 700px;
      --efxContainerPadding: 30px 0;
    }
  }
  