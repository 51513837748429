@import "./efxVariables.scss";

.efxContainer {
    width: var(--efxContainerWidth);
    padding: var(--efxContainerPadding);
    margin: var(--efxContainerMargin);
  }
  
  /* Buttons Start */
  .efxButton {
    display: inline;
    border: 2px solid transparent;
    color: #fff;
    border-radius: var(--efxInputRadius);
    padding: 16px 32px;
    font-weight: 600;
    margin: 15px 0;
    transition: .2s;
    cursor: pointer;
  }
  a.efxButton {
    display: inline-block;
    text-align: center;
    text-decoration: none;
  }
  
  .efxButton:disabled {
    cursor: auto;
  }
  
  .efxButtonFullWidth {
    width: 100%;
  }
  
  /* Primary Button */
  .efxButtonPrimary {
    background-color: var(--efxPrimary);
  }
  
  .efxButtonPrimary:focus {
    outline: 2px solid var(--efxInteractiveFocus);
    outline-offset: 2px;
  }
  
  .efxButtonPrimary:active:hover {
    background-color: var(--efxInteractiveLight);
  }
  
  .efxButtonPrimary:hover {
    background-color: var(--efxInteractiveDark);
    color: #fff;
  }
  
  .efxButtonPrimary:disabled {
    background-color: var(--efxInteractiveDisabled);
  }
  
  /* Secondary Button */
  .efxButtonSecondary {
    background-color: #fff;
    border: 2px solid var(--efxPrimary);
    color: var(--efxPrimary);
  }
  
  .efxButtonSecondary:focus {
    outline: 2px solid var(--efxInteractiveFocus);
    outline-offset: 2px;
    background-color: var(--efxInteractiveDark);
    color: #fff;
  }
  
  .efxButtonSecondary:active:hover {
    background-color: var(--efxInteractiveLight);
  }
  
  .efxButtonSecondary:hover {
    background-color: var(--efxInteractiveDark);
    color: #fff;
  }
  
  .efxButtonSecondary:disabled {
    background-color: #fff;
    border: 2px solid var(--efxInteractiveDisabled);
    color: var(--efxInteractiveDisabled);
  }
  
  /* Empty Button */
  .efxButtonEmpty {
    background-color: transparent;
    border: 2px solid transparent;
    color: var(--efxPrimary);
    outline: 2px solid transparent;
  }
  
  .efxButtonEmpty:focus {
    outline: 2px solid transparent;
    outline-offset: 2px;
    background-color: transparent;
    color: var(--efxInteractiveDark);
  }
  
  .efxButtonEmpty:active:hover {
    color: var(--efxInteractiveLight);
  }
  
  .efxButtonEmpty:hover {
    color: var(--efxInteractiveDark);
  }
  
  .efxButtonEmpty:disabled {
    background-color: transparent;
    color: var(--efxInteractiveDisabled);
  }
  
  /* Buttons End */
  
  
  /* Checkbox Start */
  /* https://www.w3schools.com/howto/howto_css_custom_checkbox.asp */
  /* Update to w3schools sample. Modified colors and added disabled class */
  .efxCheckContainer {
    display: inline;
    position: relative;
    padding-left: 25px;
    margin-bottom: 25px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  
  /* Hide the browser's default checkbox */
  .efxCheckContainer input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
  
  /* Create a custom checkbox */
  .efxCheckbox {
    position: absolute;
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    background-color: #fff;
    border-radius: var(--efxInputRadius);
    border: 2px solid var(--efxBackgroundDark);
    transition: .2s;
  }
  
  /* On mouse-over, add a grey background color */
  .efxCheckContainer:hover input ~ .efxCheckbox {
    background-color: var(--efxInteractiveDark);
  }
  
  /* When the checkbox is checked, add a blue background */
  .efxCheckContainer input:checked ~ .efxCheckbox {
    background-color: var(--efxInteractiveLight);
    border: 2px solid transparent;
  }
  
  .efxCheckContainer input:disabled ~ .efxCheckbox {
    background-color: var(--efxBackgroundDark);
    border: 2px solid transparent;
  }
  
  .efxCheckContainer input:hover:disabled:checked ~ .efxCheckbox {
    background-color: var(--efxBackgroundDark);
    border: 2px solid transparent;
  }
  
  .efxCheckContainer input:hover:checked ~ .efxCheckbox {
    background-color: var(--efxInteractiveDark);
  }
  
  /* Create the checkmark/indicator (hidden when not checked) */
  .efxCheckbox:after {
    content: "";
    position: absolute;
    display: none;
  }
  
  /* Show the checkmark when checked */
  .efxCheckContainer input:checked ~ .efxCheckbox:after {
    display: block;
  }
  
  /* Style the checkmark/indicator */
  .efxCheckContainer .efxCheckbox:after {
    left: 9px;
    top: 5px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
  
  /* Checkbox End */
  
  
  /* Radio Start */
  /* https://www.w3schools.com/howto/howto_css_custom_checkbox.asp */
  /* Update to w3schools sample. Modified colors and added disabled class */
  /* The container */
  .efxRadioContainer {
    display: inline;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    top:25px;
  }
  
  /* Hide the browser's default radio button */
  .efxRadioContainer input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
  
  /* Create a custom radio button */
  .efxRadio {
    position: absolute;
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    background-color: #fff;
    border: 2px solid var(--efxBackgroundDark);
    transition: .2s;
    border-radius: 50%;
  }
  
  /* On mouse-over, add a grey background color */
  .efxRadioContainer:hover input ~ .efxRadio {
    background-color: var(--efxInteractiveDark);
  }
  
  /* When the radio button is checked, add a blue background */
  .efxRadioContainer input:checked ~ .efxRadio {
    background-color: var(--efxInteractiveLight);
    border: 2px solid transparent;
  }
  
  .efxRadioContainer input:disabled ~ .efxRadio {
    background-color: var(--efxBackgroundDark);
    border: 2px solid transparent;
  }
  
  .efxRadioContainer input:hover:disabled:checked ~ .efxRadio {
    background-color: var(--efxBackgroundDark);
    border: 2px solid transparent;
  }
  
  .efxRadioContainer input:hover:checked ~ .efxRadio {
    background-color: var(--efxInteractiveDark);
  }
  
  /* Create the indicator (the dot/circle - hidden when not checked) */
  .efxRadio:after {
    content: "";
    position: absolute;
    display: none;
  }
  
  /* Show the indicator (dot/circle) when checked */
  .efxRadioContainer input:checked ~ .efxRadio:after {
    display: block;
  }
  
  /* Style the indicator (dot/circle) */
  .efxRadioContainer .efxRadio:after {
    top: 5px;
    left: 5px;
    width: 11px;
    height: 11px;
    border-radius: 50%;
    background: white;
  }
  /* Radio End */
  
  
  
  /* Error Banner Start */
  .efxErrorBanner {
    width: 100%;
    background-color: var(--efxBackgroundError);
    padding: 16px;
    color: var(--efxTextError);
  }
  
  .efxErrorBanner div {
    max-width: 450px;
    margin: 0 auto;
    display: flex;
    align-items: center;
  }
  
  .efxErrorBanner div i {
    font-size: 32px;
    flex-grow: 0;
    padding: 0;
    margin: 0 16px 0 0;
    width: 32px;
    height: 32px;
  }
  
  .efxErrorBanner div span {
    font-weight: 700;
    flex-grow: 1;
  }
  /* Error Banner End */
  
  /* Tooltip Start */
  .efxTooltip {
    position: relative;
    display: inline-block;
  }
  
  .efxTooltip .efxTooltipText {
    max-width: 250px;
    min-width: 150px;
    background-color: #333D47;
    color: #fff;
    text-align: left;
    border-radius: 6px;
    padding: 16px;
    opacity: 0;
    display: none;
    position: absolute;
    font-weight: 600;
    z-index: 100;
  }
  
  .efxTooltip:hover .efxTooltipText {
    opacity: 1;
    display: inline-block;
  }
  
  .efxTooltipTop {
    transform: translate(-60%, -105%);
  }
  
  .efxTooltipLeft {
    transform: translate(-125%, -50%);
  }
  
  .efxTooltipRight {
    transform: translate(5%, -50%);
  }
  
  .efxTooltipBottom {
    transform: translate(-50%, 0%);
    top: 40px;
  }
  
  
  /* Tooltip End */
  /* Modal Start */
  
  .efxModalContent {
    width: 100%;
    background-color: #fff;
    padding: 16px;
  }
  
  .efxModalHeader {
    display: flex;
    align-items: center;
  }
  
  .efxModalTitle {
    flex: 1;
    text-align: center;
    font-size: 20px;
  }
  
  .efxModalClose {
    flex-grow: 0;
    border: 0;
    outline: 0;
    padding: 0;
    margin: 0;
    font-size: 30px;
    background-color: transparent;
    color: var(--efxTextLight);
    cursor: pointer;
  }
  
  button.efxModalClose:focus, button.efxModalClose:active {
    border: 0;
    outline: 0;
  }
  
  .efxModalDescription {
    margin: 20px 0;
    text-align: center;
  }
 
  /* Modal End */
  
  