@import "./efxVariables.scss";
@import "./efxCommonComponents.scss";
@import "https://fonts.googleapis.com/css2?family=Open+Sans:wght@300&display=swap";

body {
    margin: 0 !important;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !important;
    font-size: 1rem !important;
    font-weight: 400 !important;
    line-height: 1.5 !important;
}
